<template>
  <section class="m-merchant-classify m-scroll">
    <!-- 头部导航 -->
    <div class="merchant-info">
      <img @click="$router.go(-1)"  class="info-left" src="@/assets/icon_back@2x.png" alt="back">
      <div class="info-right">
        <img :src="$store.state.merchant.pic_url" alt="merchant">
        <div class="center">
          <p>{{$store.state.merchant.title}}</p>
          <div>
            <div>
              <van-rate  v-model="$store.state.merchant.score" :size="12" color="#F03C18" void-icon="star" void-color="#ccc" readonly/>
            </div>
            <p>{{$store.state.merchant.score.toFixed(1)}}</p>
          </div>
        </div>
        <div class="followed" @click="handleFollowed()" v-if="$store.state.merchant.is_follow != null">Attentioned</div>
        <div class="followed" @click="handleFollowed()" v-if="$store.state.merchant.is_follow == null">Attention</div>
      </div>
    </div>

    <div class="merchant-search">
      <div class="search-box">
        <img src="@/assets/public/icon_search@2x.png" alt="search">
        <input v-model="search" type="text" placeholder="What are you looking for?" @change="goSearch()">
      </div>
      <div class="merchant-bar" @click="picker_1 = true">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>

    <!-- 分类数据 -->

    <div class="classify-item m-padding" v-for="(it, index) in options" :key="index">
        <h3>{{it.title_en}}</h3>
        <router-link :to="{ path: '/en/merchant/goodsList', query: { id:$route.params.id, tid: two.id, title:two.title_en   } }" class="title" v-for="(two, ind) in it.two_type" :key="ind">
          <span>{{two.title_en}}</span>
          <img src="@/assets/public/icon-fanhui@2x.png" alt="">
        </router-link>
      </div>


    <!-- 商家导航 -->
    <van-popup v-model="picker_1" round position="bottom" class="merchant-bar" closeable>

      <p class="bar-title van-hairline--bottom">{{$store.state.merchant.title}}</p>

      <router-link :to="`/en/merchant/index/${$route.params.id}`"  class="module-cell van-hairline--bottom">
        <div class="left">
          <img src="@/assets/merchant/icon_dianpu@2x.png" alt="return">
          <span>Homepage</span>
        </div>
        <div class="right">
          <img src="@/assets/public/icon-fanhui@2x.png" alt="link">
        </div>
      </router-link>

      <!-- <router-link :to="`/en/merchant/classify/${$route.params.id}`"  class="module-cell van-hairline--bottom">
        <div class="left">
          <img src="@/assets/merchant/icon_fenlei.png" alt="return">
          <span>Classification</span>
        </div>
        <div class="right">
          <img src="@/assets/public/icon-fanhui@2x.png" alt="link">
        </div>
      </router-link> -->

      <!-- <router-link :to="`/en/merchant/chat/${$route.params.id}`"  class="module-cell van-hairline--bottom">
        <div class="left">
          <img src="@/assets/merchant/icon_kefu.png" alt="return">
          <span>Customer service</span>
        </div>
        <div class="right">
          <img src="@/assets/public/icon-fanhui@2x.png" alt="link">
        </div>
      </router-link> -->

    </van-popup>

  </section>
</template>

<script>
import { classify, follow } from '@/api/zh/merchant.js'
export default {
  name:'MerchantClassify',
  data(){
    return {

      picker_1: false,
      search: '',
      options: [],
    }
  },

  mounted(){
  
  },


  methods:{
    handleBack(){
      this.$router.go(-1)
    },


    // 搜索
    goSearch() {
      if(this.search != '') {
        this.$router.push( { path: '/en/search', query: {s: 'merchant', key: this.search, id: this.$route.params.id} })
      }
    },

    getData() {
      let params = { shops_id: this.$route.params.id, }
      classify(params).then(res => {
        if(res) {
          this.options = res.data
        }
      })
    },

    // 点击关注
    handleFollowed() {
      let params = {
        status: this.$store.state.merchant.is_follow != null ? 0 : 1
      }
      follow(this.$route.params.id, params).then(res => {
        if(res) {
          if(this.$store.state.merchant.is_follow == null) {
            this.$store.state.merchant.collect_num ++
            this.$store.state.merchant.is_follow = 1
          } else {
            this.$store.state.merchant.collect_num --
            this.$store.state.merchant.is_follow = null
          }
          this.$notify({ type: 'success', message: res.msg })
        }
      })
    },
  },
  created() {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
@import './classify.less';
</style>
