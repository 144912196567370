/** 
 *   商家
 */
import { get, post } from '@/untils/js/axios.js'

// 商家信息
export const info = params => get('/shops/details', params)


// 商家商品
export const goods = params => get('/shops/goods', params)

// 商家 上新
export const goods_new = params => get('/shops/goods', params)

// 商家 热销
export const goods_hot = params => get('/shops/goods', params)


// 商家分类
export const classify = params => get('/shops/type', params)

// 商家分类商品
export const classify_goods = params => get('/shops/type-goods', params)

// 关注 商家
export const follow = (id, params) => post(`/shops/follow-cancel/${id}`, params)